import * as React from "react"
import "../App.scss";
import Section from "../components/Section/Section";
import Row from "../components/Row/Row";
import Col from "../components/Col/Col";
import CtaBar from "../components/CtaBar/CtaBar";
import Review from "../components/Review/Review";
import Reviews from "../components/Reviews/Reviews";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import Button from "../components/Button/Button";
import video from "../img/video.mp4"
import imageQuestion1 from "../img/q1.jpg"
import blurText from "../img/blurText.png"
import circle from "../img/circle.svg"

import review1 from "../img/review1.jpg"
import review2 from "../img/review2.jpg"
import review3 from "../img/review3.jpg"
import review4 from "../img/review4.jpg"
import review5 from "../img/review5.jpg"
import review6 from "../img/review6.jpg"
import review7 from "../img/review7.jpg"
import review8 from "../img/review8.jpg"
import review9 from "../img/review9.jpg"
import review10 from "../img/review10.jpg"
import review11 from "../img/review11.jpg"
import review12 from "../img/review12.jpg"
import review13 from "../img/review13.jpg"
import review14 from "../img/review14.jpg"
import review15 from "../img/review15.jpg"
import review16 from "../img/review16.jpg"
import review17 from "../img/review17.jpg"
import review18 from "../img/review18.jpg"
import review19 from "../img/review19.jpg"
import review20 from "../img/review20.jpg"
import review21 from "../img/review21.jpg"
import review22 from "../img/review22.jpg"
import review23 from "../img/review23.jpg"
import review24 from "../img/review24.jpg"
import review25 from "../img/review25.jpg"
import review26 from "../img/review26.jpg"
import review27 from "../img/review27.jpg"
import review28 from "../img/review28.jpg"
import review29 from "../img/review29.jpg"
import review30 from "../img/review30.jpg"
import review31 from "../img/review31.jpg"
import review32 from "../img/review32.jpg"
import review33 from "../img/review33.jpg"
import review34 from "../img/review34.jpg"
import review35 from "../img/review35.jpg"
import review36 from "../img/review36.jpg"
import review37 from "../img/review37.jpg"
import review38 from "../img/review38.jpg"
import review39 from "../img/review39.jpg"
import review40 from "../img/review40.jpg"
import review41 from "../img/review41.jpg"
import review42 from "../img/review42.jpg"
import review43 from "../img/review43.jpg"
import review44 from "../img/review44.jpg"
import review45 from "../img/review45.png"
import review46 from "../img/review46.png"
import review47 from "../img/review47.png"
import review48 from "../img/review48.png"
import review49 from "../img/review49.png"
import review50 from "../img/review50.png"

import playIcon from "../img/playIcon.svg"
import heroImage from "../img/hero_team.jpg"
import mindtrepreneurStrip from "../img/mindtrepreneur_strip.jpg"


// data

{/*

const pos = { x : 0, y : 0 };

const saveCursorPosition = function(x, y) {
    pos.x = (x / window.innerWidth).toFixed(2);
    pos.y = (y / window.innerHeight).toFixed(2);
    document.documentElement.style.setProperty('--x', pos.x);
    document.documentElement.style.setProperty('--y', pos.y);
}

document.addEventListener('mousemove', e => { saveCursorPosition(e.clientX, e.clientY); })

*/}

{/*

const one = document.getElementById('hero');

window.addEventListener('scroll', () => {
  let scrollY = window.scrollY;
  document.documentElement.style.setProperty('--scrollPosition', scrollY);
})

*/}

// markup
const Testimonials = () => {
  return (
    <main>
      <Nav />
      <Section className="hero heroLow" id="hero">
        <Row>

          <Col size="50">
            <h1>Success stories</h1>
          </Col>
          <Col size="50" className="hero__button">
            <Button type="secondary" text="Get Started" link="https://www.7days7figures.com/join" />
          </Col>
        </Row>
      </Section>

      <Section className="reviews reviewsAll">

          <Review
            id="review__45"
            link="https://player.vimeo.com/video/760268838"
            image={review45}
            className="active"
            name="Marcus Gilhooley"
            text="After working with Jon for some time and then with the whole Mindtrepreneur community I really discovered that the Mindset is the most important thing in business and in life as well. And this allowed me to reach my full potential and build a successful business hitting 7-figures while having a healthy and happy life which is for me the most important thing in the world."
          />
          <Review
            id="review__46"
            link="https://player.vimeo.com/video/760267330"
            image={review46}
            className="active"
            name="Just de Haan"
            text="Thanks to Jon and Vash I was able to uncover things that had been controlling my life for like 10,15 years and the insights I have right now, the value I got is something totally out of this world. Here I understood that not everything in business is about tactics but more about the Mindset and the emotions which are controlling our lives on a day-to-day basis."
          />
          <Review
            id="review__47"
            link="https://player.vimeo.com/video/760256839"
            image={review47}
            className="active"
            name="Alessandro Minneci"
            text="I have never met two individuals who are taking it so seriously like Jon and Vash. For them it is really about providing value, I can truly say that from the bottom of my heart. They taught me that the way to get to my goals is to really level up as a person first and then the rest will follow."
          />
          <Review
            id="review__48"
            link="https://player.vimeo.com/video/760269355"
            image={review48}
            className="active"
            name="Mia Alagna"
            text="I can honestly say that Jon and Vash changed my life. They helped me to get more confidence, self love, more happiness and hand on my heart, if it wasn't for Jon and Vash I would not be the person I am today. These two guys are the most genuine people I have ever met in my whole life and if you want to transform your life, don't think more and join us."
          />
          <Review
            id="review__49"
            link="https://player.vimeo.com/video/760270392"
            image={review49}
            className="active"
            name="Oscar Raita"
            text="Meeting guys and the whole community around Mindtrepreneur had been truly life changing. I didn't even realize how much bad things were stuck inside me which were blocking me from achieving my goals. And also being around like minded people gave me so much energy which showed me what can be really accomplished in life."
          />
          <Review
            id="review__50"
            link="https://player.vimeo.com/video/760271381"
            image={review50}
            className="active"
            name="Vedank Mohan"
            text="I joined Mindtrepreneur while my business was already doing well but I am so amazed how many things I still have to learn. And thanks to guys I realized that everything starts inside me. That you can have financial goals as big as you want, but before you start working with your true self nothing big is going to happen or you will not just enjoy it as much. I couldn't be happier for joining the community which has been my best investment ever and I can't recommend it enough."
          />
          <Review
            id="review__1"
            link="https://player.vimeo.com/video/609028016/"
            image={review1}
            className="active"
            name="Enver Mehmet"
            text="I had all the courses from all the gurus, I knew what to do but I wasn't doing it. Since joining Mindtrepreneur I totally transformed my life, transformed my business - hitting $10,000 a month - and ultimately my identity. I feel more abundant & my relationships are now more fulfilling. This program will serve me for the rest of my life."
          />
          <Review
            id="review__2"
            link="https://player.vimeo.com/video/707764108/"
            image={review2}
            className="active"
            name="Craig Stewart"
            text="Jon & Vash always help me discover the root cause of problems and help me overcome them. This allowed me to scale up the business, improve my relationships, be more confident, and much more. I am super grateful and my life is completely different."
          />
          <Review
            id="review__3"
            link="https://player.vimeo.com/video/710762777/"
            image={review3}
            className="active portrait"
            name="Tyrelle Anderson-Brown"
            text="Before starting with Jon, it was tough, I wasn't making much money, I didn't have any routines or systems. Once I locked in with Jon, it changed my life. Since then, I've been able to travel the world, I own $20 million crypto fond. Mindset is really the key to everything."
          />
          <Review
            id="review__4"
            link="https://player.vimeo.com/video/650549212/"
            image={review4}
            className="active"
            name="Mac Magyaros"
            text="Hands down the most valuable program and the best decision I’ve made as an entrepreneur. It totally transformed my life - I am happier in my life, I have a clear vision and purpose now, and I have a consistent way to sign clients! I can’t say enough good things about Mindtrepreneur."
          />
          <Review
            id="review__5"
            link="https://player.vimeo.com/video/636168183/"
            image={review5}
            className="active portrait"
            name="Deyan Babic"
            text="Jon & Vash helped me so much to achieve my goal of living life of my dreams. Before joining Mindtrepreneur, I was working so hard, I was absolutely not happy almost depressed, and I got less results. Now I follow the advice from Jon and Vash, I work less hours and get more results."
          />
          <Review
            id="review__6"
            link="https://player.vimeo.com/video/710824344/"
            image={review6}
            className="active portrait"
            name="Xabiso Mdluli"
            text="Because of Mindtrepreneur, I became not only a better entrepreneur, but also a better person - somebody I am proud of, in love with. Having Jon & Vash as my mentors is honestly the best thing that happened to me. I am so grateful to have them as my mentors, they honestly changed my life."
          />
          <Review
            id="review__7"
            link="https://player.vimeo.com/video/609022225/"
            image={review7}
            className="active portrait"
            name="Eric Rozhko"
            text="The Mindtrepreneur program is absolutely insane, it's been life-changing for me. Before working with Jon & Vash, my agency was struggling at $2,000, within 2 months I hit $12,000 and this month I am about to make $30,000 a month. These guys literally overdeliver and they are super transparent."
          />
          <Review
            id="review__8"
            link="https://player.vimeo.com/video/646809820/"
            image={review8}
            className="active"
            name="Juliet C. Lee"
            text="I've invested in many courses and, honestly, this is the only program where I actually got results. Jon & Vash always overdeliver, you can really tell that they actually care about the people in the program. They cover everything to have successful and happy life. This is literally the best thing that happened to me."
          />
          <Review
            id="review__9"
            link="https://player.vimeo.com/video/669841694/"
            image={review9}
            className="active portrait"
            name="Nicholas William Young"
            text="I actually hated the course when I first got into it. I was grinding so much and seeing no results before joining. But the course is not only going to change your business, it's going to change your life. After joining Mind Systems I finally hit the $10k a month mark."
          />
          <Review
            id="review__10"
            link="https://player.vimeo.com/video/617150514/"
            image={review10}
            className="active portrait"
            name="Matthew James"
            text="After working with Jon & Vash my business has completely transformed from where it was, my mindset and the way I think in general transformed. They are always overdelivering on the calls."
          />
          <Review
            id="review__11"
            link="https://player.vimeo.com/video/616891033/"
            image={review11}
            className="active portrait"
            name="Erik Erdei"
            text="I had all the courses under the sun, I signed a few clients but always lost them. Until finally Mindtrepreneur has totally changed the game! Super thankful for what you, guys, are doing. I managed to sign my biggest client! You guys always overdeliver, you should 10x the price!"
          />
          <Review
            id="review__12"
            link="https://player.vimeo.com/video/609025737/"
            image={review12}
            className="active"
            name="Benjamin Hyppönen"
            text="I felt lost, making $2,000 a month with my agency. Now, I am hitting $10,000 a month consistently. It's been definitely the best investment I have ever done."
          />
          <Review
            id="review__13"
            link="https://player.vimeo.com/video/720144454/"
            image={review13}
            className="active portrait"
            name="Malika Ulmasova"
            text="Mindtrepreneur program changed my life completely - I’ve grown professionally, personally, and financially. This course will help you live fulfilled life."
          />
          <Review
            id="review__14"
            link="https://player.vimeo.com/video/720144502/"
            image={review14}
            className="active portrait"
            name="Olley Thorpe"
            text="I can’t thank enough to Jon & Vash for creating such an amazing community."
          />
          <Review
            id="review__15"
            link="https://player.vimeo.com/video/720144801/"
            image={review15}
            className="active portrait"
            name="Dawson Josiah Wright"
            text="I am so happy I joined, because the lessons I am learning will serve for life. Jon & Vash always overdeliver, it’s insane."
          />
          <Review
            id="review__16"
            link="https://player.vimeo.com/video/720144993/"
            image={review16}
            className="active portrait"
            name="Lukas Brack"
            text="This program totally shifted my whole reality, they helped me break the old patterns that were not serving me."
          />
          <Review
            id="review__17"
            link="https://player.vimeo.com/video/720145038/"
            image={review17}
            className="active portrait"
            name="Michael Buratinsky"
            text="I am really grateful that I joined the community as it has impacted my life massively - I tripled my income and quit my old job."
          />
          <Review
            id="review__18"
            link="https://player.vimeo.com/video/720145056/"
            image={review18}
            className="active portrait"
            name="Sam Haycock"
            text="This program totally changed my life, I can’t thank Jon & Vash enough. Highly recommend these guys!"
          />
          <Review
            id="review__19"
            link="https://player.vimeo.com/video/710819820/"
            image={review19}
            className="active portrait"
            name="David Steinke"
            text="This is the best course I’ve ever purchased and I bought loads of courses. The lessons Jon & Vash teach are invaluable."
          />
          <Review
            id="review__20"
            link="https://player.vimeo.com/video/720144454/"
            image={review20}
            className="active portrait"
            name="Nabeel Mohammed"
            text="Jon is the moving force in the mindset space, I've never met somebody who is as good as he is. Before meeting Jon, I knew exactly what to do to grow my business but I wasn't doing it. With Jon's help I went from $400 to $14,000 a month, without him I wouldn't be here!"
          />
          <Review
            id="review__21"
            link="https://player.vimeo.com/video/666750652/"
            image={review21}
            className="active portrait"
            name="Romiel Clarke"
            text="Jon & Vash are phenomenal, if you join these guys I promise it'll be the best investment you've ever made. Since joining the program I've signed at least 4 additional clients."
          />
          <Review
            id="review__22"
            link="https://player.vimeo.com/video/705713157/"
            image={review22}
            className="active portrait"
            name="Kjetil Longva"
            text="Jon & Vash transformed my life and business, this is life-changing program. I’ve worked with many different coaches, but I can say hands down you won’t get better coaches than Jon & Vash."
          />
          <Review
            id="review__23"
            link="https://player.vimeo.com/video/710819788/"
            image={review23}
            className="active portrait"
            name="Christoph Martin"
            text="It’s been one of my best investments on my journey. Jon & Vash are always overdelivering in every area."
          />
          <Review
            id="review__24"
            link="https://player.vimeo.com/video/646440575/"
            image={review24}
            className="active portrait"
            name="Abbas Lilani"
            text="I've been through a quite few courses, and these guys want to one billion percent help you the most, they deliver the most value and they overdeliver on everything they promise. What they teach genuinely changed my life & my business. I already 10Xed the investment!"
          />
          <Review
            id="review__25"
            link="https://player.vimeo.com/video/627384671/"
            image={review25}
            className="active portrait"
            name="Jacob Billings"
            text="These guys changed the trajectory of my life FOREVER in every area of my life. They taught how to be my best friend and coach, which totally changed my business and personal life."
          />
          <Review
            id="review__26"
            link="https://player.vimeo.com/video/641412900/"
            image={review26}
            className="active portrait"
            name="Harry Hayward"
            text="It's been the most impactful thing I've ever done, it's truly life-changing. The best thing about these guys is that they really overdeliver, they show up every day, they are doing exactly what they are teaching!"
          />
          <Review
            id="review__27"
            link="https://player.vimeo.com/video/619977374/"
            image={review27}
            className="active portrait"
            name="Martin Svarc"
            text="Pulling the trigger on Mindtrepreneur was one of the best decision I've done in my life. This is the best program for massive change in your business and life. I work less, get more results, and have better relationships. The whole community is like a family to me."
          />
          <Review
            id="review__28"
            link="https://player.vimeo.com/video/619978539/"
            image={review28}
            className="active portrait"
            name="Robert Frisk"
            text="From the bottom of my heart, I can tell you, it's been the most ROI positive thing I've ever done in my life. The best investment I've even made, not only for myself but my business as well."
          />
          <Review
            id="review__29"
            link="https://player.vimeo.com/video/621692242/"
            image={review29}
            className="active portrait"
            name="Kieren Kane Moule"
            text="I used to hop from courses and I cannot explain how Mindtrepreneur  program changed me, as a person. We signed 4 clients in the last 2 weeks, after years of not getting anything. There is nothing like this!"
          />
          <Review
            id="review__30"
            link="https://player.vimeo.com/video/619978601/"
            image={review30}
            className="active"
            name="Jose Iglesias"
            text="It's been best 3 months of my life.  After working with Jon and Vash and doing exactly what they told me to do, my confidence level increased to the moon, my energy level is highest it has even been & I am the happiest I've ever been. It's been the best investment in my entire life."
          />
          <Review
            id="review__31"
            link="https://player.vimeo.com/video/646103575/"
            image={review31}
            className="active portrait"
            name="Mateusz Wis"
            text="Just want to say thank you for the whole course, the live calls, and the community! I've learned how to be real leader."
          />
          <Review
            id="review__32"
            link="https://player.vimeo.com/video/619979232/"
            image={review32}
            className="active portrait"
            name="Alberto Gonzalez"
            text="These guys really gone well and beyond on breaking down the steps to succeed. Both of them have so much energy that you will enjoy. They are always looking to really truthfully give value. They are very genuine guys."
          />
          <Review
            id="review__33"
            link="https://player.vimeo.com/video/616890015/"
            image={review33}
            className="active portrait"
            name="Cezary Korczyński"
            text="I was struggling to build an agency for the past two years but working with Jon & Vash I was able to sign 4 clients in 4 days. For the first time I feel I can build this business. The value of the program is HUGE.  Joining this program was the best decision of this year."
          />
          <Review
            id="review__34"
            link="https://player.vimeo.com/video/623350689/"
            image={review34}
            className="active"
            name="Brian J H Ng"
            text="I've invested $10,000+ in courses and Mindtrepreneur program was the most practical one out of all of them. I struggled with my confidence, now I have more confidence in myself, it flows naturally and I have more energy. I've just signed $4,000 deal."
          />
          <Review
            id="review__35"
            link="https://player.vimeo.com/video/646095980/"
            image={review35}
            className="active"
            name="Jamaal Rowe"
            text="Both Jon & Vash deliver so much value! They've changed my life, business, loved life, and health! The whole community is like a family. This is course will change your whole life forever!"
          />
          <Review
            id="review__36"
            link="https://player.vimeo.com/video/646098489/"
            image={review36}
            className="active"
            name="Chris Ciprian"
            text="Since joining the program, I've seen dramatic changes in my life, not just in my business! These changed would have never happened if I didn't invest in you, guys."
          />
          <Review
            id="review__37"
            link="https://player.vimeo.com/video/644501019/"
            image={review37}
            className="active"
            name="Jorge Alvare portrait"
            text="I knew what steps should do with my business but I felt short and I had no idea what was going on. Since joining Mindtrepreneur, I learned how to overcome the inner battles and overcome my struggles. This program helped me a ton!"
          />
          <Review
            id="review__38"
            link="https://player.vimeo.com/video/711394802/"
            image={review38}
            className="active portrait"
            name="Alex Hii"
            text="This has been the most transformational, life-changing course for me."
          />
          <Review
            id="review__39"
            link="https://player.vimeo.com/video/710820535/"
            image={review39}
            className="active portrait"
            name="Inessa Schäfer"
            text="This course has been life-changing, I knew what to do before but because my mindset I wasn’t taking any action. Jon & Vash are amazing coaches."
          />
          <Review
            id="review__40"
            link="https://player.vimeo.com/video/710822564/"
            image={review40}
            className="active portrait"
            name="Joshua Wilson"
            text="This course helped me find my purpose and get attuned with it. This propels me to do the actions and get what I want."
          />
          <Review
            id="review__41"
            link="https://player.vimeo.com/video/710823515/"
            image={review41}
            className="active portrait"
            name="Kenji Ongalo"
            text="Before joining Mindtrepreneur, I was stuck. A few months later, I turned my passion of writing to profitable business. The principles I’ve learned are serving me every day."
          />
          <Review
            id="review__42"
            link="https://player.vimeo.com/video/710823885/"
            image={review42}
            className="active portrait"
            name="Kyle May"
            text="Whether you are just starting out or you’ve been an entrepreneur for multiple years, this is community you have to be part of. I haven’t met anybody who is as caring and passionate about students’ success."
          />
          <Review
            id="review__43"
            link="https://player.vimeo.com/video/710824271/"
            image={review43}
            className="active portrait"
            name="Tilesh Patel"
            text="Big shoutout to Jon & Vash for the amazing course and community they’ve created, and the excellence they put into it. There is nothing like it out there."
          />
          <Review
            id="review__44"
            link="https://player.vimeo.com/video/711399243/"
            image={review44}
            className="active"
            name="Shehan Solangaarachchi"
            text="Before joining Mindtrepreneur, I was inconsistent, sabotaging myself, and had a lot of self-doubt. Now I am so confident in my goals with no doubt I will get there. Jon & Vash are the people to learn from, they genuinely want to help."
          />
      </Section>


      <Footer/>

    </main>
  )
}

export default Testimonials
